@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0');

* {
  font-family: 'Poppins', sans-serif;
}

.home {
  background: url('../assetes/img/profitxbg.png') no-repeat center center fixed;
  background-size: cover;
  color: white;
}

.rectBg {
  position: absolute;
  top: 0;
  right: 0;
  width: 550px;
  height: auto;
  z-index: 0;
}

.rect-leftBg {
  position: absolute;
  left: 0;
  width: 320px;
  height: auto;
}

.rect-leftBg img{
  display: block;
  width: 130%;
}

.home-navbar {
  padding: 15px 20px;
  top: 0;
  z-index: 1000; /* Ensure it stays above other content */
}

.home-navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;
  transition: all 0.3s ease-in-out;
}

.home-logo {
  font-size: 24px;
  font-weight: bold;
}

.home-logo-highlight {
  color: #31DAFF;
}

.home-logo-image {
  height: 40px; /* Adjust the height as needed */
  width: auto; /* Maintain aspect ratio */
}

.home-nav-links {
  letter-spacing: .5px;
  transition: all .5s ease-in-out;
  position: relative;
  display: flex;
}

.home-nav-links li {
  margin-left: 20px;
}

.home-nav-links a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  margin: 0 18px;
}

.home-nav-links a:hover {
  color: #29b8d8;
  transition: all .5s ease-in-out;
}

.home-nav-actions {
  display: flex;
  align-items: center;
}

.home-profile-icon img {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  cursor: pointer;
}

.home-cta-button {
  background-color: #31DAFF;
  color: #181A1E;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
}

.home-cta-button:hover {
  background-color: #29b8d8;
}

/* New styles for scrolled state */
.home-navbar-container.scrolled {
  padding: 14px 20px;
  background: #80808057;
  border-radius: 40px;
  transition: background 0.3s ease-in-out; /* Ensure smooth transition */
}



.home-heroSection {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  position: relative; /* Ensure positioning context */
}

.home-heroContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  max-width: 1200px;
  margin: auto;
}

.home-colLeft h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  margin: .75rem 0;
  background: linear-gradient(87.67deg, #3D8ACE 1.98%, #42B6CF 30%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.home-separator {
  width: 64px;
  height: 1px;
  background-color: rgba(194, 208, 228, .5);
  margin-bottom: 1rem;
}

.home-colLeft h1 {
  font-size: 52px;
  line-height: 1.3;
  color: #fff;
  font-weight: 800;
}

.home-colLeft h1 span {
  background: -webkit-linear-gradient(87.67deg, #47CCE9 1.98%, #2D9EB7 19.5%, #3D8ACE 82.86%);
  -webkit-background-clip: text;
  -webkit-text-stroke: 3px transparent;
  padding: 0 0 3px 2px;
  color: #101217;
}

.home-colLeft p {
  font-size: 22px;
  color: rgba(194, 208, 228, .5);
  margin: 1rem 0 2rem;
  padding: 0;
  letter-spacing: .5px;
}

.home-colLeft {
  color: #fff;
  max-width: 800px;
}

.home-colRight {
  display: flex;
  justify-content: center;
  max-width: 600px;
  z-index: 0;
}

.home-features-section {
  color: #333;
  padding: 50px 20px;
  text-align: center;
  position: relative;
}

.home-features-section h1 {
  font-size: 42px;
  line-height: 1.3;
  color: #fff;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.home-features-section h1 img {
  padding-left: 10px;
}

.home-features-section-heading {
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-features-section p {
  font-size: 16px;
  color: #797e86;
  font-weight: 400;
  padding-bottom: 20px;
}

.home-features-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto 50px;
}

.home-features-text h2 {
  font-size: 36px;
  color: #333;
}

.home-features-text p {
  font-size: 18px;
  color: #666;
}

.home-features-text .cta-button {
  background-color: #31DAFF;
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 25px;
  font-size: 18px;
  cursor: pointer;
}

.home-features-text .cta-button:hover {
  background-color: #1FBFD4;
}

.home-features-image {
  max-width: 60%;
}

.home-features-image img {
  width: 800px;
  height: auto;
}

.home-platforms-container {
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0 auto;
}

.home-platforms-container img {
  max-width: 80%;
  height: auto;
}

/*dropdown */

.home-features-text h3 {
  text-align: left;
  color: #1FBFD4;
  font-size: 30px;
}

.home-dropdown {
  margin-bottom: 10px;
  position: relative;
  width: 400px;
  text-align: left;
}

.home-dropdown-btn {
  background-color: transparent;
  color: #5c5c5c;
  font-weight: 800;
  padding: 10px 15px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  text-align: left;
}

.home-dropdown.active .home-dropdown-btn {
  color: #f5f5f5;
}

.home-dropdown-content {
  color: #333;
  display: none;
  padding: 0 15px; /* Remove initial padding */
  border-radius: 5px;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s;
}

.home-dropdown.active {
  --color1: #2cd9ff;
  --color2: #7effb2;
  --color3: #f4ff7a;
  --color4: #ffd80e;
  --gradientAngle: 1080deg;
  transition: all 0.3s;
  border: 3px solid transparent;
  border-radius: 20px;
  background: linear-gradient(#181A1E, #181A1E) padding-box, linear-gradient(var(--gradientAngle), var(--color1), var(--color2), var(--color3), var(--color4)) border-box;
  position: relative;
}

.home-dropdown.active .home-dropdown-content {
  display: block;
  background: #181A1E; /* Content background to differentiate from border */
  -webkit-border-radius: 25px;
  border-radius: 25px; /* Inner content border-radius */
  max-height: 500px; /* Adjust based on content size */
  padding: 10px 15px;
  transition: max-height 0.5s ease, padding 0.5s ease; /* Ensure transition timing matches */
}

.home-dropdown-btn:hover {
  background-color: #ffffff00;
}

.home-dropdown-btn::after {
  content: '';
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%) rotate(0deg);
  width: 20px;
  height: 20px;
  background-image: url('../assetes/img/arrow-right.png');
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.home-dropdown.active .home-dropdown-btn::after,
.home-dropdown-btn:hover::after,
.home-dropdown-btn.active::after {
  opacity: 1;
  transform: translateY(-50%) rotate(-90deg);
}

.main-content {
  padding: 0px;
  overflow: scroll;
  scrollbar-width: none;
  scroll-behavior: smooth;
}



/* Dummy How It Works*/

.how-it-works-section {
  position: relative;
  z-index: 1;
}

.how-it-works-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 200%;
  background: url('../assetes/img/left_bg.webp') no-repeat left center;
  background-size: contain;
  z-index: -1; /* Ensure the background is behind the content */
}

.how-it-works-container,
.how-it-works-colLeft,
.how-it-works-colRight,
.how-it-works-products {
  position: relative;
  z-index: 2; /* Ensure these sections are above the background */
}

.how-it-works-container {
  display: flex;
  width: 1400px;
  margin: auto;
}

.rectBg-simple {
  position: relative;
  width: 320px;
  height: auto;
  right: 0;
  z-index: 0;
}

.how-it-works-colLeft h1 {
  font-size: 42px;
  line-height: 1.3;
  color: #fff;
  font-weight: 600;
  display: flex;
}

.how-it-works-colLeft h4 {
  font-size: 24px;
  line-height: 1.3;
  color: #ffffff;
  font-weight: 400;
  display: flex;
  background-color: #d9d9d915;
  width: 120px;
  padding: 10px;
  justify-content: center;
  border-radius: 40px;
}

.how-it-works-colLeft p {
  font-size: 16px;
  line-height: 1.3;
  color: #A3A3A3;
  font-weight: 400;
  display: flex;
}

.how-it-works-colRight {
  width: 1400px;
}

.dummy-sheet-edit {
  border-radius: 10px;
  color: white;
}

.dummy-sheet-edit h2 {
  margin-bottom: 20px;
}

.dummy-sheet-edit input[type="text"] {
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #3c3c3c75;
  color: white;
}

.dummy-sheet-edit button {
  padding: 10px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: white;
  cursor: pointer;
}

.dummy-sheet-edit button:hover {
  background-color: #555;
}


.dummy-product-table, .dummy-product-table-list {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.dummy-product-table th, .dummy-product-table td {
  padding: 20px;
  text-align: left;
  color: white;
  text-align: center;
}

.dummy-product-table-list ,.dummy-product-table-list td {
  padding: 5px;
  text-align: left;
  color: white;
  text-align: center;
}

.dummy-product-table th, .dummy-product-table-list th {
  border-bottom: 1px solid #2f3239;
  font-weight: 700;
  font-size: 14px;
}

.dummy-product-table tr {
  border-bottom: 1px solid #2f3239;
}

.dummy-product-table input[type="checkbox"], .dummy-product-table-list input[type="checkbox"]{
  margin: 0;
  width: 20px;
  height: 20px;
  display: inline-block;
  visibility: visible;
}

.dummy-product-table input[type="text"], .dummy-product-table-list input[type="text"] {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #3330;
  color: white;
}

.dummy-product-table tbody tr:hover {
  background: rgba(128, 128, 128, 0.103);
}

.dummy-product-table select, .dummy-product-table-list select {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #4440;
  color: white;
  cursor: pointer;
  transition: 0.3s;
}

.dummy-product-table-list select:hover {
  background: #4443;
}


.dummy-product-table-list select option {
  background-color: #1FBFD4;
  border-radius: 20px;
  padding: 5px;
}

.dummy-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.dummy-save-prices, .dummy-delete-products, .dummy-add-product {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: white;
  cursor: pointer;
}

.how-it-works-products {
  width: 1400px;
  margin: auto;
  padding: 20px;
  border-radius: 20px;
  background: #80808014;
  backdrop-filter: blur(10px);
  box-shadow: var(--box-shadow);
}


/* End */

/* Pricing */
.home-pricing-container {
  padding-top: 40px;
}

.home-pricing-heading {
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 100px;
  
}

.home-logo-heading {
display: flex;
gap: 8px;
align-items: center;
align-items: center;
justify-content: center;
}

.home-pricing-heading h1 {
  font-size: 42px;
  line-height: 1.3;
  color: #fff;
  font-weight: 600;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.home-pricing-heading p {
font-size: 26px;
color: #797e86;
font-weight: 300;
padding-bottom: 20px;
}

.home-business-image img {
  width: 60%;
  margin: auto;
}

.home-pricing-table-wrapper {
  display: flex;
  gap: 10rem;
  justify-content: center;
}

.home-pricing-table-free {
  background: #d9d9d92e;
  padding: 20px;
  border-radius: 20px;
  border: 2px solid white;
  width: 400px;
}

.table-free-heading h2 {
  font-size: 40px;
  font-weight: 300;
  margin-bottom: 0;
}

.table-free-heading h3 {
  font-size: 40px;
  font-weight: 500;
}

.home-pricing-table-free p {
  font-size: 20px;
  font-weight: 300;
  padding-bottom: 10px;
}

.table-free-content li{
  display: flex;
  font-size: 18px;
  font-weight: 400;
  align-items: center;
  padding-bottom: 10px;
}

.table-free-content li img{
  width: 32px;
  height: auto;
  padding-right: 10px;
}

/* Premium */
.table-business-heading, .table-free-heading {
  display: flex;
  align-items: center;
  gap: 10px;
}


.table-business-heading img, .table-free-heading img {
  width: 80px;
  height: 80px;
}

.home-pricing-table-business {
  background: #d9d9d92e;
  padding: 20px;
  border-radius: 20px;
  border: 2px solid #42B6CF;
  width: 400px;
}

.table-business-heading h2 {
  font-size: 40px;
  font-weight: 300;
  margin-bottom: 0;
}

.table-business-heading h3 {
  font-size: 40px;
  font-weight: 500;
}

.home-pricing-table-business p {
  font-size: 20px;
  font-weight: 300;
  padding-bottom: 10px;
}

.table-business-content li{
  display: flex;
  font-size: 18px;
  font-weight: 400;
  align-items: center;
  padding-bottom: 10px;
}

.table-business-content li img{
  width: 32px;
  height: auto;
  padding-right: 10px;
}

.table-free-button, .table-business-button {
  text-align: center;
  transition: all 0.3s ease-in-out; /* Add transition here */
}

.table-free-cta {
  padding: 10px 80px;
  border-radius: 20px;
  border: 2px #1FBFD4 solid;
  background: linear-gradient(87.67deg, #d9d9d941 2%, #7373732f 70%);
  color: white;
  font-size: 16px;
  font-weight: 400;
}

.table-free-cta:hover {
background: linear-gradient(87.67deg, #7373732f 2%, #d9d9d941 70%);
}

.table-business-cta {
  padding: 10px 80px;
  border-radius: 20px;
  border: 2px #1FBFD4 solid;
  background: linear-gradient(87.67deg, #42b5cf73 4%, #3d8ace3d 70%);
  color: white;
  font-size: 16px;
  font-weight: 400;
}

.table-business-cta:hover {
  background: linear-gradient(87.67deg, #3d8ace3d 4%, #42b5cf73 70%);
  }

.home-pricing-table-wrapper {
  padding-bottom: 20px;
  }

  .home-pricing-section {
  padding-top: 20px;
  }

  /* 6GRID */

  .features-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding-top: 40px;
    justify-items: center;
    padding-bottom: 20px;
    width: 1200px;
    margin: auto;
  }
  
  .feature-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: linear-gradient(87.67deg, #7373732f 2%, #d9d9d924 70%);
    padding: 20px;
    border-radius: 10px;
    border: 1px #ffffff solid;
    width: 300px;
    height: 100px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-align: center;
    transition: transform 0.5s ease, background 0.5s ease, border-color 0.5s ease;
  }
  
  .feature-item:hover {
    transform: scale(1.05);
    background: linear-gradient(87.67deg, #d9d9d924 2%, #7373732f 70%);
    border-color: #29b8d8;
  }
  
  .feature-item img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
  
  .feature-item p {
    color: white;
    font-size: 20px;
    text-align: center;
    font-weight: 300;
  }

  /* FAQ Section */

.faq-heading h1 {
  font-size: 42px;
    line-height: 1.3;
    color: #fff;
    font-weight: 600;
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
    padding-top: 100px;
  }

  .faq-boxes {
    margin: auto;
    gap: 150px;
    display: flex;
    width: 1400px;
    justify-content: center;
    padding-bottom: 60px;
  }

  .faq-box-email {
    padding: 20px;
    border-radius: 20px;
    --color1: #3b69cc;
    --color2: #4694c2;
    --color4: #ffffff;
    --gradientAngle: 45deg; /* Adjusted to 135deg for diagonal gradient */
    transition: all 0.3s;
    border: 2px solid transparent;
    border-radius: 20px;
    background: linear-gradient(#181a1e, #181A1E) padding-box,
                linear-gradient(var(--gradientAngle), var(--color1), var(--color2), var(--color4)) border-box;
    position: relative;
    width: 400px;
  }

  .faq-box-email p{
    font-size: 18px;
    font-weight: 300;
    padding-bottom: 10px;
  }

.faq-box-email-cta {
  background: #7b7b7b52;
  color: white;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px;
  transition: all 0.3s;
}

.faq-box-email-cta img {
  width: 30px;
  height: auto;
}

.faq-box-email-cta:hover{
  background: rgba(128, 128, 128, 0.425);

}

.faq-box-gyik {
  padding: 20px;
  border-radius: 20px;
background: #74747421;
  width: 400px;
}

.faq-box-gyik p{
  font-size: 18px;
  font-weight: 300;
  padding-bottom: 10px;
}

.faq-box-gyik-cta {
background: linear-gradient(90deg, #1575a1, #74b4d1);
color: white;
font-size: 16px;
font-weight: 400;
}

.faq-bottom-container {
  gap: 40px;
}

.top5-cta {
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #31DAFF;
  color: #181A1E;
  cursor: pointer;
}

.faq-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.faq-item {
  background-color: #1e2228;
  padding: 10px 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-item::after {
  content: '>';
  color: #31DAFF;
}

.faq-bottom-left h2 span{
  background: -webkit-linear-gradient(87.67deg, #47CCE9 1.98%, #2D9EB7 19.5%, #3D8ACE 82.86%);
    -webkit-background-clip: text;
    -webkit-text-stroke: 3px transparent;
    padding: 0 0 3px 2px;
    color: #101217;
}

.faq-bottom-left h2{
  font-size: 70px;
}

.faq-bottom-left p{
  font-size: 20px;
  font-weight: 200;
  width: 80%;
}


.top5-cta {
  font-weight: 400;
  font-size: 20px;
}

.faq-content {
  display: flex;
  gap: 40px;
  width: 950px;
  margin: auto;
}

.faq-bottom {
  display: flex;
  width: 1400px;
  justify-content: space-between;
  margin: auto;
  padding-bottom: 100px;
}

.faq-bottom-left, .faq-bottom-right {
  width: 50%;
}


.faq-dropdown {
  margin-bottom: 10px;
  position: relative;
  width: 100%;
  text-align: left;

}

.faq-dropdown-btn {
  background-color: #d9d9d942;
  color: #c9c9c9;
  font-weight: 600;
  padding: 10px 15px;
  border: none;
  font-size: 24px;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  text-align: left;
}

.faq-dropdown-btn:hover {
  background: #d9d9d98c;
}

.faq-dropdown.active .faq-dropdown-btn {
  color: #f5f5f5;
  font-weight: 700;
}

.faq-dropdown-content {
  background-color: #2c2f33;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 5px;
  display: none;
}

.faq-dropdown-content p {
  font-size: 16px;
}

.faq-dropdown.active .faq-dropdown-content {
  display: block;
}

/* Footer */

.footerSection {
  color: white;
  padding: 40px 0;
  text-align: center;
}

.footer-container {
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
  padding-top: 40px;
}

.footer-links, .footer-contact, .footer-social {
  flex: 1;
}

.footer-links h3, .footer-contact h3, .footer-social h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.footer-links ul, .footer-social ul {
  list-style: none;
  padding: 0;
  width: 40%;
  margin: auto;
}

.footer-links li, .footer-social li {
  margin-bottom: 10px;
}

.footer-links a, .footer-social a {
  color: #29b8d8;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover, .footer-social a:hover {
  color: #47cce9;
}

.footer-contact p {
  margin: 5px 0;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.social-icons li {
  display: inline;
}

.social-icons a {
  font-size: 18px;
}

.footer-separator {
  width: 700px;
  height: 1px;
  background-color: rgba(194, 208, 228, .5);
  margin: auto;
}

.app-download {
  display: flex;
  padding-top: 20px;
  gap: 20px;
}

.app-store, .google-play {
  width: 30%;
  height: auto;
}