.loginContainer {
    position: relative;
    width: 70vw;
    height: 80vh;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
    overflow: hidden;
    margin: auto;
    top: 4rem;
  }
  
  .loginContainer::before {
    content: "";
    position: absolute;
    top: 0;
    left: -50%;
    width: 100%;
    height: 100%;
    background: linear-gradient(-45deg, #071C2E, #031728);
    z-index: 6;
    transform: translateX(100%);
    transition: 1s ease-in-out;
  }
  
  .signin-signup {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 5;
  }
  
  form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 40%;
    min-width: 238px;
    padding: 0 10px;
  }
  
  form.sign-in-form {
    opacity: 1;
    transition: 0.5s ease-in-out;
    transition-delay: 1s;
  }
  
  form.sign-up-form {
    opacity: 0;
    transition: 0.5s ease-in-out;
    transition-delay: 1s;
  }
  
  .loginTitle {
    font-size: 35px;
    color: #28a4dd;
    margin-bottom: 10px;
  }
  
  .input-field {
    width: 100%;
    height: 50px;
    background: #f0f0f0;
    margin: 10px 0;
    border: 2px solid #28a4dd;
    border-radius: 50px;
    display: flex;
    align-items: center;
  }
  
  .input-field i {
    flex: 1;
    text-align: center;
    color: #666;
    font-size: 18px;
  }
  
  .input-field input {
    flex: 5;
    background: none;
    border: none;
    outline: none;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    color: #444;
  }
  
  .loginBtn {
    width: 150px;
    height: 50px;
    border: none;
    border-radius: 50px;
    background: #28a4dd;
    color: #fff;
    font-weight: 600;
    margin: 10px 0;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .loginBtn:hover {
    background: #0e4b68;
  }
  
  .error {
    color: red;
    margin-bottom: 1rem;
  }
  
  .panels-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  
  .panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 35%;
    min-width: 238px;
    padding: 0 10px;
    text-align: center;
    z-index: 6;
  }
  
  .left-panel {
    pointer-events: none;
  }
  
  .loginContent {
    color: #fff;
    transition: 1.1s ease-in-out;
    transition-delay: 0.5s;
  }
  
  .panel h3 {
    font-size: 24px;
    font-weight: 600;
  }
  
  .panel p {
    font-size: 15px;
    padding: 10px 0;
  }
  
  .loginImage {
    width: 100%;
    transition: 1.1s ease-in-out;
    transition-delay: 0.4s;
  }
  
  .left-panel .loginImage,
  .left-panel .loginContent {
    transform: translateX(-200%);
  }
  
  .right-panel .loginImage,
  .right-panel .loginContent {
    transform: translateX(0);
  }
  
  .account-text {
    display: none;
  }
  
  /*Animation*/
  .loginContainer.sign-up-mode::before {
    transform: translateX(0);
  }
  
  .loginContainer.sign-up-mode .right-panel .loginImage,
  .loginContainer.sign-up-mode .right-panel .loginContent {
    transform: translateX(200%);
  }
  
  .loginContainer.sign-up-mode .left-panel .loginImage,
  .loginContainer.sign-up-mode .left-panel .loginContent {
    transform: translateX(0);
  }
  
  .loginContainer.sign-up-mode form.sign-in-form {
    opacity: 0;
  }
  
  .loginContainer.sign-up-mode form.sign-up-form {
    opacity: 1;
  }
  
  .loginContainer.sign-up-mode .right-panel {
    pointer-events: none;
  }
  
  .loginContainer.sign-up-mode .left-panel {
    pointer-events: all;
  }
  
  /*Responsive*/
  @media (max-width:779px) {
    .loginContainer {
      width: 100vw;
      height: 100vh;
    }
  }
  
  @media (max-width:635px) {
    .loginContainer::before {
      display: none;
    }
    form {
      width: 80%;
    }
    form.sign-up-form {
      display: none;
    }
    .loginContainer.sign-up-mode2 form.sign-up-form {
      display: flex;
      opacity: 1;
    }
    .loginContainer.sign-up-mode2 form.sign-in-form {
      display: none;
    }
    .panels-container {
      display: none;
    }
    .account-text {
      display: initial;
      margin-top: 30px;
    }
  }
  
  @media (max-width:320px) {
    form {
      width: 90%;
    }
  }