.payment-return-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(87.67deg, #181a1e 1.98%, #2b2d33 82.86%);
  padding: 20px;
}

.payment-status-card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  padding: 40px;
  border-radius: 20px;
  width: 90%;
  max-width: 500px;
  text-align: center;
  transition: all 0.5s ease;
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.5s ease forwards;
}

@keyframes slideUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.payment-status-card.success {
  border: 2px solid transparent;
  background: linear-gradient(#181a1e, #181a1e) padding-box, linear-gradient(45deg, #47cce9, #2d9eb7) border-box;
}

.payment-status-card.failed {
  border: 2px solid transparent;
  background: linear-gradient(#181a1e, #181a1e) padding-box, linear-gradient(45deg, #ff6b6b, #ff4757) border-box;
}

.payment-status-card h2 {
  color: #ffffff;
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: 600;
}

.payment-status-card p {
  font-size: 16px;
  margin-bottom: 25px;
  color: #a3a3a3;
  line-height: 1.6;
}

.payment-status-card .error-message {
  color: #ff6b6b;
  margin-bottom: 20px;
  font-size: 14px;
}

.payment-status-card button {
  padding: 12px 30px;
  border-radius: 30px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  background: linear-gradient(87.67deg, #47cce9 1.98%, #2d9eb7 82.86%);
  color: #181a1e;
}

.payment-status-card button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(71, 204, 233, 0.2);
}

.payment-status-card button.login-button {
  background: linear-gradient(87.67deg, #3d8ace 1.98%, #42b6cf 82.86%);
}

.loading-spinner {
  width: 50px;
  height: 50px;
  margin: 0 auto 30px;
  border: 3px solid rgba(71, 204, 233, 0.1);
  border-top: 3px solid #47cce9;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.success-icon,
.error-icon {
  font-size: 60px;
  margin-bottom: 20px;
  animation: scaleIn 0.5s ease forwards;
}

.success-icon {
  color: #47cce9;
}

.error-icon {
  color: #ff6b6b;
}

@keyframes scaleIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.redirect-message {
  margin-top: 15px;
  color: #47cce9;
  font-size: 14px;
  opacity: 0.8;
}

.countdown {
  display: inline-block;
  min-width: 20px;
  font-weight: bold;
}
