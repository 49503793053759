@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
  }
  
  .popup-alert {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #a36109;
    color: white;
    padding: 15px 30px;
    border-radius: 25px; /* Adjusted for a more rounded border */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    align-items: center;
    text-align: center;
    animation: fadeIn 0.5s, fadeOut 0.5s 2.5s; /* Adjust timing as needed */
  }
  
  .popup-alert.success {
    background-color: #3D3D3D;
    color: white;
    font-size: 14px;
  }
  
  .popup-alert.error {
    background-color: #3D3D3D;
    color: white;
    font-size: 14px;
  }
  
  .popup-alert img.popup-alert-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }