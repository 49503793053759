.esemenyek-container {
    padding: 20px;
    background-color: var(--color-white);
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    border: 1px solid #a3a3a38c;
    color: #333;
  }
  
  .esemenyek-container h1 {
    margin-bottom: 20px;
    font-size: 2em;
    color: #333;
  }
  
  .statistics, .insights {
    margin-bottom: 20px;
  }
  
  .statistics h2, .insights h2 {
    font-size: 1.5em;
    color: #007bff;
    margin-bottom: 10px;
  }
  
  .statistics ul, .insights ul {
    list-style: none;
    padding: 0;
  }
  
  .statistics li, .insights li {
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .statistics li:last-child, .insights li:last-child {
    border-bottom: none;
  }
  
  .statistics p, .insights p {
    color: #777;
  }
  