.settings-container {
  width: 80%;
  margin: 2rem auto;
  padding: 2rem;
  background-color: var(--color-white);
  border-radius: 1rem;
  box-shadow: none;
  transition: 300ms all;
}

.settings-container:hover {
  box-shadow: 0 2rem 3rem rgba(0, 0, 0, 0.4);
}

.section {
  margin-bottom: 2rem;
}

h2 {
  margin-bottom: 1rem;
  color: #e0e0e0;
}

.account-info, .notification-info {
  border: 1px solid #ffffff;
  padding: 1rem;
  border-radius: 1rem;
}

.info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid #a3a3a38c;
}

.info-row:last-child {
  border-bottom: none;
}

.info-title {
  flex: 1;
  color: #e0e0e0;
}

.info-detail {
  flex: 2;
  text-align: left;
  padding-left: 1rem;
  color: #c0c0c0;
}

.info-action {
  flex: 1;
  text-align: right;
}

button {
  background-color: #ffffff;
  border: none;
  color: rgb(0, 0, 0);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 0.5rem; /* Add margin to the left for spacing */
}

button:first-of-type {
  margin-left: 0; /* Remove margin for the first button */
}

button:hover {
  background-color: var(--color-primary);
}

.toggle-button {
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  cursor: pointer;
  border: none;
  font-weight: bold;
}

.toggle-button.on {
  background-color: #4cd586;
  color: #1e1e1e;
}

.toggle-button.off {
  background-color: #e06e6e;
  color: #1e1e1e;
}

/* New Styles for Inputs and Messages */
.nickname-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #333333;
  border-radius: 0.5rem;
  background-color: #ffffff;
  color: #000000;
  font-weight: 450;
}

.nickname-input:focus {
  border-color: #7380ec;
  outline: none;
}

.success, .error {
  margin-top: 1rem;
  font-size: 0.9rem;
}

.success {
  color: #4cd586;
}

.error {
  color: #e06e6e;
}