@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0');

:root {
  --color-primary: #28a4dd;
  --color-danger: #28a4dd;
  --color-success: #4cd586;
  --color-warning: #ffbb55;
  --color-white: #7373730;
  --color-info-dark: #7d8da1;
  --color-info-light: #dce1eb;
  --color-dark: #edeffd;
  --color-light: rgba(0, 0, 0, 0.4);
  --color-primary-variant: #111e88;
  --color-dark-variant: #a3bdcc;
  --color-background: #181a1e;
  --color-danger-red: #e06e6e;

  --card-border-radius: 2rem;
  --border-radius-1: 0.4rem;
  --border-radius-2: 0.8rem;
  --border-radius-3: 1.2rem;

  --card-padding: 1.8rem;
  --padding-1: 1.2rem;

  --box-shadow: 0 4px 4px rgb(79 122 155 / 25%), 0 4px 25px rgb(11 83 91 / 5%), 10px 1.57706px 25px rgb(77 207 196 / 4%);
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

html {
  font-size: 14px;
}

body {
  width: 100vw;
  height: 100vh;
  font-family: 'Poppins', sans-serif;
  font-size: 0.88rem;
  user-select: none;
  overflow-x: hidden;
  color: var(--color-dark);
  background: url('../assetes/img/profitxbg.png')
}

.container {
  display: grid;
  width: 96%;
  margin: 0 auto;
  gap: 1.8rem;
  grid-template-columns: 14rem auto;
}

a {
  color: var(--color-dark);
}

img {
  display: block;
  width: 100%;
}

h1 {
  font-weight: 800;
  font-size: 1.8rem;
}

h2 {
  font-size: 1.4rem;
}

h3 {
  font-size: 0.87rem;
}

h4 {
  font-size: 0.8rem;
}

h5 {
  font-size: 0.77rem;
}

small {
  font-size: 0.75rem;
}

.profile-photo {
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  overflow: hidden;
}

.text-muted {
  color: var(--color-info-dark);
}

p {
  color: var(--color-dark-variant);
}

b {
  color: var(--color-dark);
}

.primary {
  color: var(--color-primary);
}

.danger {
  color: var(--color-danger);
}

.danger-red {
  color: var(--color-danger-red);
}

.success {
  color: var(--color-success);
}

.warning {
  color: var(--color-warning);
}

/* Sidebar Styles */
.sidebar-container {
  display: flex;
  height: 100vh;
  border-right: 1px solid rgba(194, 208, 228, .2);
}

aside {
  width: 200px;
  display: flex;
  flex-direction: column;
}

aside .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-top: 1.4rem;
  margin-left: 20px;
}

aside .logo {
  display: flex;
  gap: 0.8rem;
}

aside .logo img {
  width: 100%;
  height: auto;
}

aside .close {
  display: none;
}

aside .sidebar {
  display: flex;
  flex-direction: column;
  height: 86vh;
  position: relative;
  top: 2rem;
}

aside .sidebar a,
aside .sidebar .link {
  display: flex;
  align-items: center;
  color: var(--color-info-dark);
  margin-left: 2rem;
  gap: 1rem;
  position: relative;
  height: 3.7rem;
  transition: all 300ms ease;
  cursor: pointer; /* Add cursor: pointer to all links */
}

aside .sidebar a .material-symbols-outlined,
aside .sidebar a .link_text,
aside .sidebar .link .material-symbols-outlined,
aside .sidebar .link .link_text {
  transition: margin-left 300ms ease;
}

aside .sidebar a:last-child,
aside .sidebar .link:last-child {
  position: absolute;
  bottom: 2rem;
  width: 100%;
}

aside .sidebar a.active,
aside .sidebar .link.active {
  background: linear-gradient(90deg, #3f3f3fbf, #6f363600 95%);
  color: var(--color-primary);
  margin-left: 0;
}

aside .sidebar a.active:before,
aside .sidebar .link.active:before {
  content: '';
  width: 6px;
  height: 100%;
  background: var(--color-primary);
}

aside .sidebar a.active .material-symbols-outlined,
aside .sidebar a.active .link_text,
aside .sidebar .link.active .material-symbols-outlined,
aside .sidebar .link.active .link_text {
  color: var(--color-primary);
}

aside .sidebar a:hover,
aside .sidebar .link:hover {
  color: var(--color-primary);
}

aside .sidebar a:hover .material-symbols-outlined,
aside .sidebar a:hover .link_text,
aside .sidebar .link:hover .material-symbols-outlined,
aside .sidebar .link:hover .link_text {
  margin-left: 1rem;
}

aside .sidebar .message-count {
  background: var(--color-danger);
  color: var(--color-white);
  padding: 2px 10px;
  font-size: 11px;
  border-radius: var(--border-radius-1);
}