.models-edit-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.market-details {
  background: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  margin: 20px 0;
}

.action-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.delete-button {
  background-color: #ff4444;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: #cc0000;
}

.delete-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.back-button {
  background-color: #666666;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.back-button:hover {
  background-color: #444444;
}

.error-message {
  color: #ff4444;
  margin-top: 20px;
  padding: 10px;
  background-color: #ffeeee;
  border-radius: 4px;
}
