/* src/pages/LandingPage/LandingPage.css */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.home {
  background: url("../../assetes/img/profitxbg.png") no-repeat center center fixed;
  background-size: cover;
  color: white;
  min-height: 100vh;
}

/* Import all the existing CSS styles */
/* styles.css content */

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0");

* {
  font-family: "Poppins", sans-serif;
}

.home {
  background: url("../../assetes/img/profitxbg.png") no-repeat center center fixed;
  background-size: cover;
  color: white;
}

.rectBg {
  position: absolute;
  top: 0;
  right: 0;
  width: 550px;
  height: auto;
  z-index: 0;
}

.rect-leftBg {
  position: absolute;
  left: 0;
  width: 320px;
  height: auto;
}

.rect-leftBg img {
  display: block;
  width: 130%;
}

.home-navbar {
  padding: 15px 20px;
  top: 0;
  z-index: 1000; /* Ensure it stays above other content */
}

.home-navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;
  transition: all 0.3s ease-in-out;
}

.home-logo {
  font-size: 24px;
  font-weight: bold;
}

.home-logo-highlight {
  color: #31daff;
}

.home-logo-image {
  height: 40px; /* Adjust the height as needed */
  width: auto; /* Maintain aspect ratio */
}

.home-nav-links {
  letter-spacing: 0.5px;
  transition: all 0.5s ease-in-out;
  position: relative;
  display: flex;
}

.home-nav-links li {
  margin-left: 20px;
}

.home-nav-links a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  margin: 0 18px;
}

.home-nav-links a:hover {
  color: #29b8d8;
  transition: all 0.5s ease-in-out;
}

.home-nav-actions {
  display: flex;
  align-items: center;
}

.home-profile-icon img {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  cursor: pointer;
}

.home-cta-button {
  background-color: #31daff;
  color: #181a1e;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
}

.home-cta-button:hover {
  background-color: #29b8d8;
}

/* New styles for scrolled state */
.home-navbar-container.scrolled {
  padding: 14px 20px;
  background: #80808057;
  border-radius: 40px;
  transition: background 0.3s ease-in-out; /* Ensure smooth transition */
}

.home-heroSection {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  position: relative; /* Ensure positioning context */
}

.home-heroContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  max-width: 1200px;
  margin: auto;
}

.home-colLeft h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  margin: 0.75rem 0;
  background: linear-gradient(87.67deg, #3d8ace 1.98%, #42b6cf 30%);
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.home-separator {
  width: 64px;
  height: 1px;
  background-color: rgba(194, 208, 228, 0.5);
  margin-bottom: 1rem;
}

.home-colLeft h1 {
  font-size: 52px;
  line-height: 1.3;
  color: #fff;
  font-weight: 800;
}

.home-colLeft h1 span {
  background: -webkit-linear-gradient(87.67deg, #47cce9 1.98%, #2d9eb7 19.5%, #3d8ace 82.86%);
  -webkit-background-clip: text;
  -webkit-text-stroke: 3px transparent;
  padding: 0 0 3px 2px;
  color: #101217;
}

.home-colLeft p {
  font-size: 22px;
  color: rgba(194, 208, 228, 0.5);
  margin: 1rem 0 2rem;
  padding: 0;
  letter-spacing: 0.5px;
}

.home-colLeft {
  color: #fff;
  max-width: 800px;
}

.home-colRight {
  display: flex;
  justify-content: center;
  max-width: 600px;
  z-index: 0;
}

.home-features-section {
  color: #333;
  padding: 50px 20px;
  text-align: center;
  position: relative;
}

.home-features-section h1 {
  font-size: 42px;
  line-height: 1.3;
  color: #fff;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.home-features-section h1 img {
  padding-left: 10px;
}

.home-features-section-heading {
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-features-section p {
  font-size: 16px;
  color: #797e86;
  font-weight: 400;
  padding-bottom: 20px;
}

.home-features-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto 50px;
}

.home-features-text h2 {
  font-size: 36px;
  color: #333;
}

.home-features-text p {
  font-size: 18px;
  color: #666;
}

.home-features-text .cta-button {
  background-color: #31daff;
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 25px;
  font-size: 18px;
  cursor: pointer;
}

.home-features-text .cta-button:hover {
  background-color: #1fbfd4;
}

.home-features-image {
  max-width: 60%;
}

.home-features-image img {
  width: 800px;
  height: auto;
}

.home-platforms-container {
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0 auto;
}

.home-platforms-container img {
  max-width: 80%;
  height: auto;
}

/*dropdown */

.home-features-text h3 {
  text-align: left;
  color: #1fbfd4;
  font-size: 30px;
}

.home-dropdown {
  margin-bottom: 10px;
  position: relative;
  width: 400px;
  text-align: left;
}

.home-dropdown-btn {
  background-color: transparent;
  color: #5c5c5c;
  font-weight: 800;
  padding: 10px 15px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  text-align: left;
}

.home-dropdown.active .home-dropdown-btn {
  color: #f5f5f5;
}

.home-dropdown-content {
  color: #333;
  display: none;
  padding: 0 15px; /* Remove initial padding */
  border-radius: 5px;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s;
}

.home-dropdown.active {
  --color1: #2cd9ff;
  --color2: #7effb2;
  --color3: #f4ff7a;
  --color4: #ffd80e;
  --gradientAngle: 1080deg;
  transition: all 0.3s;
  border: 3px solid transparent;
  border-radius: 20px;
  background: linear-gradient(#181a1e, #181a1e) padding-box, linear-gradient(var(--gradientAngle), var(--color1), var(--color2), var(--color3), var(--color4)) border-box;
  position: relative;
}

.home-dropdown.active .home-dropdown-content {
  display: block;
  background: #181a1e; /* Content background to differentiate from border */
  -webkit-border-radius: 25px;
  border-radius: 25px; /* Inner content border-radius */
  max-height: 500px; /* Adjust based on content size */
  padding: 10px 15px;
  transition: max-height 0.5s ease, padding 0.5s ease; /* Ensure transition timing matches */
}

.home-dropdown-btn:hover {
  background-color: #ffffff00;
}

.home-dropdown-btn::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%) rotate(0deg);
  width: 20px;
  height: 20px;
  background-image: url("../../assetes/img/arrow-right.png");
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.home-dropdown.active .home-dropdown-btn::after,
.home-dropdown-btn:hover::after,
.home-dropdown-btn.active::after {
  opacity: 1;
  transform: translateY(-50%) rotate(-90deg);
}

.main-content {
  padding: 0px;
  overflow: scroll;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

/* Dummy How It Works*/

.how-it-works-section {
  position: relative;
  z-index: 1;
}

.how-it-works-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 200%;
  background: url("../../assetes/img/left_bg.webp") no-repeat left center;
  background-size: contain;
  z-index: -1; /* Ensure the background is behind the content */
}

.how-it-works-container,
.how-it-works-colLeft,
.how-it-works-colRight,
.how-it-works-products {
  position: relative;
  z-index: 2; /* Ensure these sections are above the background */
}

.how-it-works-container {
  display: flex;
  width: 1400px;
  margin: auto;
}

.rectBg-simple {
  position: relative;
  width: 320px;
  height: auto;
  right: 0;
  z-index: 0;
}

.how-it-works-colLeft h1 {
  font-size: 42px;
  line-height: 1.3;
  color: #fff;
  font-weight: 600;
  display: flex;
}

.how-it-works-colLeft h4 {
  font-size: 24px;
  line-height: 1.3;
  color: #ffffff;
  font-weight: 400;
  display: flex;
  background-color: #d9d9d915;
  width: 120px;
  padding: 10px;
  justify-content: center;
  border-radius: 40px;
}

.how-it-works-colLeft p {
  font-size: 16px;
  line-height: 1.3;
  color: #a3a3a3;
  font-weight: 400;
  display: flex;
}

.how-it-works-colRight {
  width: 1400px;
}

.dummy-sheet-edit {
  border-radius: 10px;
  color: white;
}

.dummy-sheet-edit h2 {
  margin-bottom: 20px;
}

.dummy-sheet-edit input[type="text"] {
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #3c3c3c75;
  color: white;
}

.dummy-sheet-edit button {
  padding: 10px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: white;
  cursor: pointer;
}

.dummy-sheet-edit button:hover {
  background-color: #555;
}

.dummy-product-table,
.dummy-product-table-list {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.dummy-product-table th,
.dummy-product-table td {
  padding: 20px;
  text-align: left;
  color: white;
  text-align: center;
}

.dummy-product-table-list,
.dummy-product-table-list td {
  padding: 5px;
  text-align: left;
  color: white;
  text-align: center;
}

.dummy-product-table th,
.dummy-product-table-list th {
  border-bottom: 1px solid #2f3239;
  font-weight: 700;
  font-size: 14px;
}

.dummy-product-table tr {
  border-bottom: 1px solid #2f3239;
}

.dummy-product-table input[type="checkbox"],
.dummy-product-table-list input[type="checkbox"] {
  margin: 0;
  width: 20px;
  height: 20px;
  display: inline-block;
  visibility: visible;
}

.dummy-product-table input[type="text"],
.dummy-product-table-list input[type="text"] {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #3330;
  color: white;
}

.dummy-product-table tbody tr:hover {
  background: rgba(128, 128, 128, 0.103);
}

.dummy-product-table select,
.dummy-product-table-list select {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #4440;
  color: white;
  cursor: pointer;
  transition: 0.3s;
}

.dummy-product-table-list select:hover {
  background: #4443;
}

.dummy-product-table-list select option {
  background-color: #1fbfd4;
  border-radius: 20px;
  padding: 5px;
}

.dummy-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.dummy-save-prices,
.dummy-delete-products,
.dummy-add-product {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: white;
  cursor: pointer;
}

.how-it-works-products {
  width: 1400px;
  margin: auto;
  padding: 20px;
  border-radius: 20px;
  background: #80808014;
  backdrop-filter: blur(10px);
  box-shadow: var(--box-shadow);
}

/* End */

/* Pricing */
.home-pricing-container {
  padding-top: 40px;
}

.home-pricing-heading {
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 100px;
}

.home-logo-heading {
  display: flex;
  gap: 8px;
  align-items: center;
  align-items: center;
  justify-content: center;
}

.home-pricing-heading h1 {
  font-size: 42px;
  line-height: 1.3;
  color: #fff;
  font-weight: 600;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.home-pricing-heading p {
  font-size: 26px;
  color: #797e86;
  font-weight: 300;
  padding-bottom: 20px;
}

.home-business-image img {
  width: 60%;
  margin: auto;
}

.home-pricing-table-wrapper {
  display: flex;
  gap: 10rem;
  justify-content: center;
}

.home-pricing-table-free {
  background: #d9d9d92e;
  padding: 20px;
  border-radius: 20px;
  border: 2px solid white;
  width: 400px;
}

.table-free-heading h2 {
  font-size: 40px;
  font-weight: 300;
  margin-bottom: 0;
}

.table-free-heading h3 {
  font-size: 40px;
  font-weight: 500;
}

.home-pricing-table-free p {
  font-size: 20px;
  font-weight: 300;
  padding-bottom: 10px;
}

.table-free-content li {
  display: flex;
  font-size: 18px;
  font-weight: 400;
  align-items: center;
  padding-bottom: 10px;
}

.table-free-content li img {
  width: 32px;
  height: auto;
  padding-right: 10px;
}

/* Premium */
.table-business-heading,
.table-free-heading {
  display: flex;
  align-items: center;
  gap: 10px;
}

.table-business-heading img,
.table-free-heading img {
  width: 80px;
  height: 80px;
}

.home-pricing-table-business {
  background: #d9d9d92e;
  padding: 20px;
  border-radius: 20px;
  border: 2px solid #42b6cf;
  width: 400px;
}

.table-business-heading h2 {
  font-size: 40px;
  font-weight: 300;
  margin-bottom: 0;
}

.table-business-heading h3 {
  font-size: 40px;
  font-weight: 500;
}

.home-pricing-table-business p {
  font-size: 20px;
  font-weight: 300;
  padding-bottom: 10px;
}

.table-business-content li {
  display: flex;
  font-size: 18px;
  font-weight: 400;
  align-items: center;
  padding-bottom: 10px;
}

.table-business-content li img {
  width: 32px;
  height: auto;
  padding-right: 10px;
}

.counter-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 16px 0;
}

.counter-btn {
  width: 32px;
  height: 32px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#counter {
  font-size: 16px;
  font-weight: bold;
}

/* Summary Section */
.home-pricing-summary {
  background: #1e1e1e; /* Subtle dark background */
  padding: 20px;
  border-radius: 20px;
  border: 2px solid #3e3e3e; /* Slightly darker border */
  max-width: 400px;
  margin: 20px auto;
  text-align: center; /* Center align for better layout */
  color: #ffffff; /* White text for readability */
}

.home-pricing-summary h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

.summary-items p {
  margin: 10px 0;
  font-size: 16px;
  line-height: 1.6;
}

.home-pricing-summary strong {
  font-size: 20px;
  color: #ffffff;
}

.summary-total1 {
  font-size: 20px;
  color: #1fbfd4; /* Highlighted color for the total */
}

.summary-order-button {
  margin-top: 20px;
}

.order-cta-button {
  background: #1fbfd4;
  color: #ffffff;
  font-size: 18px;
  padding: 12px 25px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
}

.order-cta-button:hover {
  background: #17a2bb;
  transform: scale(1.05);
}

.order-cta-button:active {
  background: #128697;
  transform: scale(0.95);
}

.table-free-cta {
  padding: 10px 80px;
  border-radius: 20px;
  border: 2px #1fbfd4 solid;
  background: linear-gradient(87.67deg, #d9d9d941 2%, #7373732f 70%);
  color: white;
  font-size: 16px;
  font-weight: 400;
}

.table-free-cta:hover {
  background: linear-gradient(87.67deg, #7373732f 2%, #d9d9d941 70%);
}

.table-business-cta {
  padding: 10px 80px;
  border-radius: 20px;
  border: 2px #1fbfd4 solid;
  background: linear-gradient(87.67deg, #42b5cf73 4%, #3d8ace3d 70%);
  color: white;
  font-size: 16px;
  font-weight: 400;
}

.table-business-cta:hover {
  background: linear-gradient(87.67deg, #3d8ace3d 4%, #42b5cf73 70%);
}

.home-pricing-table-wrapper {
  padding-bottom: 20px;
}

.home-pricing-section {
  padding-top: 20px;
}

/* 6GRID */

.features-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding-top: 40px;
  justify-items: center;
  padding-bottom: 20px;
  width: 1200px;
  margin: auto;
}

.feature-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: linear-gradient(87.67deg, #7373732f 2%, #d9d9d924 70%);
  padding: 20px;
  border-radius: 10px;
  border: 1px #ffffff solid;
  width: 300px;
  height: 100px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
  transition: transform 0.5s ease, background 0.5s ease, border-color 0.5s ease;
}

.feature-item:hover {
  transform: scale(1.05);
  background: linear-gradient(87.67deg, #d9d9d924 2%, #7373732f 70%);
  border-color: #29b8d8;
}

.feature-item img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.feature-item p {
  color: white;
  font-size: 20px;
  text-align: center;
  font-weight: 300;
}

/* FAQ Section */

.faq-heading h1 {
  font-size: 42px;
  line-height: 1.3;
  color: #fff;
  font-weight: 600;
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  padding-top: 100px;
}

.faq-boxes {
  margin: auto;
  gap: 150px;
  display: flex;
  width: 1400px;
  justify-content: center;
  padding-bottom: 60px;
}

.faq-box-email {
  padding: 20px;
  border-radius: 20px;
  --color1: #3b69cc;
  --color2: #4694c2;
  --color4: #ffffff;
  --gradientAngle: 45deg; /* Adjusted to 135deg for diagonal gradient */
  transition: all 0.3s;
  border: 2px solid transparent;
  border-radius: 20px;
  background: linear-gradient(#181a1e, #181a1e) padding-box, linear-gradient(var(--gradientAngle), var(--color1), var(--color2), var(--color4)) border-box;
  position: relative;
  width: 400px;
}

.faq-box-email p {
  font-size: 18px;
  font-weight: 300;
  padding-bottom: 10px;
}

.faq-box-email-cta {
  background: #7b7b7b52;
  color: white;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px;
  transition: all 0.3s;
}

.faq-box-email-cta img {
  width: 30px;
  height: auto;
}

.faq-box-email-cta:hover {
  background: rgba(128, 128, 128, 0.425);
}

.faq-box-gyik {
  padding: 20px;
  border-radius: 20px;
  background: #74747421;
  width: 400px;
}

.faq-box-gyik p {
  font-size: 18px;
  font-weight: 300;
  padding-bottom: 10px;
}

.faq-box-gyik-cta {
  background: linear-gradient(90deg, #1575a1, #74b4d1);
  color: white;
  font-size: 16px;
  font-weight: 400;
}

.faq-bottom-container {
  gap: 40px;
}

.top5-cta {
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #31daff;
  color: #181a1e;
  cursor: pointer;
}

.faq-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.faq-item {
  background-color: #1e2228;
  padding: 10px 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-item::after {
  content: ">";
  color: #31daff;
}

.faq-bottom-left h2 span {
  background: -webkit-linear-gradient(87.67deg, #47cce9 1.98%, #2d9eb7 19.5%, #3d8ace 82.86%);
  -webkit-background-clip: text;
  -webkit-text-stroke: 3px transparent;
  padding: 0 0 3px 2px;
  color: #101217;
}

.faq-bottom-left h2 {
  font-size: 70px;
}

.faq-bottom-left p {
  font-size: 20px;
  font-weight: 200;
  width: 80%;
}

.top5-cta {
  font-weight: 400;
  font-size: 20px;
}

.faq-content {
  display: flex;
  gap: 40px;
  width: 950px;
  margin: auto;
}

.faq-bottom {
  display: flex;
  width: 1400px;
  justify-content: space-between;
  margin: auto;
  padding-bottom: 100px;
}

.faq-bottom-left,
.faq-bottom-right {
  width: 50%;
}

.faq-dropdown {
  margin-bottom: 10px;
  position: relative;
  width: 100%;
  text-align: left;
}

.faq-dropdown-btn {
  background-color: #d9d9d942;
  color: #c9c9c9;
  font-weight: 600;
  padding: 10px 15px;
  border: none;
  font-size: 24px;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  text-align: left;
}

.faq-dropdown-btn:hover {
  background: #d9d9d98c;
}

.faq-dropdown.active .faq-dropdown-btn {
  color: #f5f5f5;
  font-weight: 700;
}

.faq-dropdown-content {
  background-color: #2c2f33;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 5px;
  display: none;
}

.faq-dropdown-content p {
  font-size: 16px;
}

.faq-dropdown.active .faq-dropdown-content {
  display: block;
}

.app-download {
  display: flex;
  padding-top: 20px;
  gap: 20px;
}

.app-store,
.google-play {
  width: 30%;
  height: auto;
}

/* sidebar.css */

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0");

:root {
  --color-primary: #28a4dd;
  --color-danger: #28a4dd;
  --color-success: #4cd586;
  --color-warning: #ffbb55;
  --color-white: #7373730;
  --color-info-dark: #7d8da1;
  --color-info-light: #dce1eb;
  --color-dark: #edeffd;
  --color-light: rgba(0, 0, 0, 0.4);
  --color-primary-variant: #111e88;
  --color-dark-variant: #a3bdcc;
  --color-background: #181a1e;
  --color-danger-red: #e06e6e;

  --card-border-radius: 2rem;
  --border-radius-1: 0.4rem;
  --border-radius-2: 0.8rem;
  --border-radius-3: 1.2rem;

  --card-padding: 1.8rem;
  --padding-1: 1.2rem;

  --box-shadow: 0 4px 4px rgb(79 122 155 / 25%), 0 4px 25px rgb(11 83 91 / 5%), 10px 1.57706px 25px rgb(77 207 196 / 4%);
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}

html {
  font-size: 14px;
}

body {
  width: 100vw;
  height: 100vh;
  font-family: "Poppins", sans-serif;
  font-size: 0.88rem;
  user-select: none;
  overflow-x: hidden;
  color: var(--color-dark);
  background: url("../../assetes/img/profitxbg.png") no-repeat center center fixed;
  background-size: cover;
}

.container {
  display: grid;
  width: 96%;
  margin: 0 auto;
  gap: 1.8rem;
  grid-template-columns: 14rem auto;
}

a {
  color: var(--color-dark);
}

img {
  display: block;
  width: 100%;
}

h1 {
  font-weight: 800;
  font-size: 1.8rem;
}

h2 {
  font-size: 1.4rem;
}

h3 {
  font-size: 0.87rem;
}

h4 {
  font-size: 0.8rem;
}

h5 {
  font-size: 0.77rem;
}

small {
  font-size: 0.75rem;
}

.profile-photo {
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  overflow: hidden;
}

.text-muted {
  color: var(--color-info-dark);
}

p {
  color: var(--color-dark-variant);
}

b {
  color: var(--color-dark);
}

.primary {
  color: var(--color-primary);
}

.danger {
  color: var(--color-danger);
}

.danger-red {
  color: var(--color-danger-red);
}

.success {
  color: var(--color-success);
}

.warning {
  color: var(--color-warning);
}

/* Sidebar Styles */
.sidebar-container {
  display: flex;
  height: 100vh;
  border-right: 1px solid rgba(194, 208, 228, 0.2);
}

aside {
  width: 200px;
  display: flex;
  flex-direction: column;
}

aside .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-top: 1.4rem;
  margin-left: 20px;
}

aside .logo {
  display: flex;
  gap: 0.8rem;
}

aside .logo img {
  width: 100%;
  height: auto;
}

aside .close {
  display: none;
}

aside .sidebar {
  display: flex;
  flex-direction: column;
  height: 86vh;
  position: relative;
  top: 2rem;
}

aside .sidebar a,
aside .sidebar .link {
  display: flex;
  align-items: center;
  color: var(--color-info-dark);
  margin-left: 2rem;
  gap: 1rem;
  position: relative;
  height: 3.7rem;
  transition: all 300ms ease;
  cursor: pointer; /* Add cursor: pointer to all links */
}

aside .sidebar a .material-symbols-outlined,
aside .sidebar a .link_text,
aside .sidebar .link .material-symbols-outlined,
aside .sidebar .link .link_text {
  transition: margin-left 300ms ease;
}

aside .sidebar a:last-child,
aside .sidebar .link:last-child {
  position: absolute;
  bottom: 2rem;
  width: 100%;
}

aside .sidebar a.active,
aside .sidebar .link.active {
  background: linear-gradient(90deg, #3f3f3fbf, #6f363600 95%);
  color: var(--color-primary);
  margin-left: 0;
}

aside .sidebar a.active:before,
aside .sidebar .link.active:before {
  content: "";
  width: 6px;
  height: 100%;
  background: var(--color-primary);
}

aside .sidebar a.active .material-symbols-outlined,
aside .sidebar a.active .link_text,
aside .sidebar .link.active .material-symbols-outlined,
aside .sidebar .link.active .link_text {
  color: var(--color-primary);
}

aside .sidebar a:hover,
aside .sidebar .link:hover {
  color: var(--color-primary);
}

aside .sidebar a:hover .material-symbols-outlined,
aside .sidebar a:hover .link_text,
aside .sidebar .link:hover .material-symbols-outlined,
aside .sidebar .link:hover .link_text {
  margin-left: 1rem;
}

aside .sidebar .message-count {
  background: var(--color-danger);
  color: var(--color-white);
  padding: 2px 10px;
  font-size: 11px;
  border-radius: var(--border-radius-1);
}

/* Footer */

.footerSection {
  color: white;
  padding: 40px 0;
  text-align: center;
}

.footer-container {
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
  padding-top: 40px;
}

.footer-links,
.footer-contact,
.footer-social {
  flex: 1;
}

.footer-links h3,
.footer-contact h3,
.footer-social h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.footer-links ul,
.footer-social ul {
  list-style: none;
  padding: 0;
  width: 40%;
  margin: auto;
}

.footer-links li,
.footer-social li {
  margin-bottom: 10px;
}

.footer-links a,
.footer-social a {
  color: #29b8d8;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover,
.footer-social a:hover {
  color: #47cce9;
}

.footer-contact p {
  margin: 5px 0;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.social-icons li {
  display: inline;
}

.social-icons a {
  font-size: 18px;
}

.footer-separator {
  width: 700px;
  height: 1px;
  background-color: rgba(194, 208, 228, 0.5);
  margin: auto;
}

/* header.css */

.home-navbar {
  padding: 15px 20px;
  top: 0;
  z-index: 1000; /* Ensure it stays above other content */
}

.home-navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;
  transition: all 0.3s ease-in-out;
}

.home-logo {
  font-size: 24px;
  font-weight: bold;
}

.home-logo-highlight {
  color: #31daff;
}

.home-logo-image {
  height: 40px; /* Adjust the height as needed */
  width: auto; /* Maintain aspect ratio */
}

.home-nav-links {
  letter-spacing: 0.5px;
  transition: all 0.5s ease-in-out;
  position: relative;
  display: flex;
}

.home-nav-links li {
  margin-left: 20px;
}

.home-nav-links a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  margin: 0 18px;
}

.home-nav-links a:hover {
  color: #29b8d8;
  transition: all 0.5s ease-in-out;
}

.home-nav-actions {
  display: flex;
  align-items: center;
}

.home-profile-icon img {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  cursor: pointer;
}

.home-cta-button {
  background-color: #31daff;
  color: #181a1e;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
}

.home-cta-button:hover {
  background-color: #29b8d8;
}

/* New styles for scrolled state */
.home-navbar-container.scrolled {
  padding: 14px 20px;
  background: #80808057;
  border-radius: 40px;
  transition: background 0.3s ease-in-out; /* Ensure smooth transition */
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.feature-item .check-icon {
  width: 24px;
  height: 24px;
}

.table-free-heading,
.table-premium-heading {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.free-heading-title h2,
.premium-heading-title h2 {
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
}

.free-heading-title h3,
.premium-heading-title h3 {
  font-size: 32px;
  font-weight: 600;
  color: #ffffff;
}

.counter-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.counter-btn {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.3);
  color: #ffffff;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.counter-btn:hover {
  background: rgba(0, 0, 0, 0.5);
}

.counter-section span {
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
  min-width: 30px;
  text-align: center;
}

/* Adjust the wrapper spacing */
.home-pricing-table-wrapper {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px;
}

/* Make pricing boxes equal width */
.home-pricing-table-free,
.home-pricing-table-business {
  flex: 1;
  max-width: 400px;
}

.embedded-checkout-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%; /* Adjust width as needed */
  max-width: 500px;
  height: auto; /* Adjust height as needed */
  max-height: 90vh;
  z-index: 1000;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  display: none; /* Initially hidden */
  padding: 20px;
  overflow-y: auto; /* Enable scrolling if content overflows */
}

.embedded-checkout-container.active {
  display: block; /* Show when active */
}

.embedded-checkout-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none; /* Initially hidden */
}

.embedded-checkout-overlay.active {
  display: block; /* Show when active */
}

.purchase-btn {
  margin-top: 20px;
  padding: 12px 24px;
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.purchase-btn:hover {
  background-color: #0052a3;
}

.purchase-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
