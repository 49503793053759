.models-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 10px;
  color: white;
}

.models {
  display: flex;
  width: 100%;
}

.models-middle-container {
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.models-middle {
  background-color: var(--color-white);
  padding: 20px;
  border-radius: 10px;
  transition: all 300ms ease;
  box-shadow: var(--box-shadow);
  border: 1px solid #a3a3a38c;
}

.market-info h3 {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #fff;
}

.info-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.info-box {
  background: #b3b3b333;
  padding: 20px;
  border-radius: 10px;
  flex: 1;
  margin-right: 10px;
  text-align: center;
}

.info-box:last-child {
  margin-right: 0;
}

.info-box p {
  margin: 0;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
}

.info-value {
  font-size: 1.2em;
  color: #2ecc71;
}

.model-bottom {
  display: flex;
  justify-content: space-between;
}

.markets {
  margin-top: 20px;
}

.markets p {
  margin-bottom: 10px;
  color: white;
}

.market-logos {
  display: flex;
  gap: 10px;
}

.market-logos img {
  width: 40px;
  height: 40px;
}

.price-sheet {
  margin-top: 20px;
}

.price-sheet label {
  display: block;
  margin-bottom: 5px;
  color: white;
}

.price-sheet select {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #444;
  background: #ffffff;
  color: rgb(0, 0, 0);
}

.models-right {
  flex: 1;
  margin-left: 20px;
  padding-left: 20px;
  border-radius: 10px;
  color: white;
  text-align: center;
}

.price-sheets {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.models-right h2 {
  margin-bottom: 10px;
  color: white;
}

.price-sheet-right-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.price-sheet-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #75727233;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  width: 80%;
  border: solid 1px #ffffff;
  position: relative;
  transition: background-color 0.3s;
}

.price-sheet-right:hover {
  background-color: #44444433;
}

.price-sheet-right:hover input[type="checkbox"] {
  display: block;
}

.checkbox-group {
  color: black;
}

.price-sheet-right .sheet-details {
  display: flex;
  align-items: center;
}

.price-sheet-right input[type="checkbox"] {
  display: none;
  margin-right: 10px;
}

.price-sheet-right p {
  margin: 0;
  text-align: left;
  color: #f3f3f3;
  font-size: 14px;
}

.price-sheet-right span {
  cursor: pointer;
  margin-left: 10px;
}

.price-sheet-right .remove-button {
  display: none;
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.price-sheet-right input[type="checkbox"]:checked ~ .remove-button {
  display: block;
}

.add-new {
  padding: 10px;
  border: 2px dashed var(--color-primary);
  border-radius: 5px;
  background-color: #33333300;
  color: var(--color-primary);
  cursor: pointer;
}

.add-new:hover {
  background: #2e78cc;
  color: white;
}

input[type="text"] {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #444;
  background: #ffffff;
  color: #000000;
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
  margin-bottom: 10px;
}

.price-sheet-right-container img {
  display: none;
  width: 30px;
  height: 30px;
}

.price-sheet-right-container:hover img {
  display: block;
  cursor: pointer;
}

/* Popup Container */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup Content */
.popup-content {
  background: white;
  padding: 30px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.pupup-title1 {
  font-size: 1.5em;
  font-weight: bold;
  color: #444;
  margin-top: 10px;
  text-align: left;
}

.pupup-description1 {
  font-size: 13px;
  color: #666;
  margin-top: -5px;
  margin-bottom: 20px;
  text-align: left;
}

/* Input Fields */
.popup-content input[type="text"],
.popup-content input[type="number"],
.popup-content select {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
  box-sizing: border-box;
}

.popup-content select {
  background: #f9f9f9;
}

/* Input Group with Icon */
.input-with-icon {
  position: relative;
  margin-bottom: 20px;
}

.input-with-icon .material-symbols-outlined {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
  font-size: 20px;
}

.input-with-icon input {
  padding-left: 35px;
}

/* Checkbox Group */
.checkbox-group {
  margin: 20px 0;
  text-align: left;
}

.checkbox-group label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  color: #444;
}

.checkbox-group input[type="checkbox"] {
  margin-right: 10px;
}

/* Buttons */
.popup-content button {
  padding: 10px 20px;
  margin-top: 10px;
  margin-right: 10px;
  background: #007bff;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.popup-content button:hover {
  background: #0056b3;
}

.popup-content .cancel-button {
  background: #f5f5f5;
  color: #666;
}

.popup-content .cancel-button:hover {
  background: #e0e0e0;
}

.minmaxPrice {
  display: flex;
  gap: 10px;
}

/* Plan Selection */
.plan-selection select {
  font-size: 14px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 6px;
}

.plan-selection p {
  font-size: 13px;
  color: #444;
}

.plan-selection {
  margin: 20px 0;
}

.quantity-selector {
  margin-top: 10px;
}

.quantity-selector input {
  width: 60px;
  margin: 0 10px;
}

.total-price {
  margin-top: 5px;
  font-weight: bold;
}

.subscription-status {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.subscription-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 15px;
}

.subscription-card {
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 15px;
  background: #f8f9fa;
}

.subscription-card.active {
  border-color: #44aa44;
}

.subscription-details {
  margin-top: 10px;
}

.usage-bar {
  height: 8px;
  background: #eee;
  border-radius: 4px;
  margin: 8px 0;
  overflow: hidden;
}

.usage-fill {
  height: 100%;
  transition: width 0.3s ease;
}

.subscription-warning {
  margin-top: 10px;
  padding: 10px;
  background: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  color: #856404;
}

.subscription-breakdown {
  margin-top: 10px;
  padding: 8px;
  background: #f8f9fa;
  border: 1px solid #eee;
  border-radius: 4px;
}

.subscription-breakdown .expiry-date {
  font-size: 0.9em;
  color: #666;
}
