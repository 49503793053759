*{
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.app-container {
  display: flex;
  height: 100vh;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  transition: margin-left 0.3s;
}

.full-width {
  margin-left: 0;
}