.products-container {
  width: 100%;
  border-radius: var(--card-border-radius);
  color: #fff;
  text-align: center;
  padding-bottom: var(--card-padding);
  transition: all 300ms ease;
  margin-top: 3rem;
}

.overview-cards {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.card {
  flex: 1;
  background: var(--color-white);
  padding: 1rem;
  border-radius: var(--card-border-radius);
  box-shadow: 0 2rem 3rem var(--color-light);
  margin: 0 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card h3 {
  margin-bottom: 1rem;
}

.card ul {
  list-style-type: none;
  padding: 0;
  width: 100%;
  text-align: left;
  counter-reset: list-counter; /* Initialize the counter */
}

.card ul li {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: var(--border-radius-1);
  display: flex;
  justify-content: space-between; /* Ensure content is spaced out */
  align-items: center;
  position: relative; /* Position relative to place the counter */
}

.card ul li::before {
  content: counter(list-counter); /* Display the counter value */
  counter-increment: list-counter; /* Increment the counter */
  font-weight: bold;
  margin-right: 0.5rem;
  color: #fff; /* Adjust the color as needed */
}

.card ul li:hover {
  cursor: pointer;
  background: rgba(128, 128, 128, 0.322);
}

.card ul li span {
  font-weight: bold;
  color: #d1d1d1;
}

.card ul li .topProfit {
  margin-left: auto; /* Push the topProfit span to the end */
  font-weight: bold;
  color: #fff; /* Adjust color if needed */
}

.table-header {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  margin-bottom: 1rem;
}

.table-header .filter {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.table-header .filter span {
  font-size: 1rem;
  color: #ecf0f1;
}

.table-header .filter select {
  padding: 0.5rem;
  border-radius: var(--border-radius-1);
  border: 1px solid #34495e;
  background-color: #34495e;
  color: #ecf0f1;
}

.products-container table {
  width: 97%;
  border-collapse: collapse;
  background: #7b7b7b2b;
  border-radius: 20px;
  margin: auto;
}

.products-container table th,
.products-container table td {
  padding: 12px 15px;
  text-align: left;
  color: white;
}

.products-container table th {
  color: #ecf0f1;
}
.products-container table tbody tr {
  border-bottom: 1px solid #80808013;
}

.products-container table tbody tr:hover {
  background: #64686b5d;
  transition: background 200ms ease;
}

.products-container table a {
  color: #28a4dd;
  text-decoration: none;
}

.products-container table .positive-profit {
  color: #4cd586;
}

.highlight {
  background-color: #64686b5d;
  transition: background-color 1s ease;
}

/* New styles for filters */
.filters {
  text-align: left;
  display: flex;
  justify-content: space-between;
  background: #7b7b7b2b;
  border-radius: 20px;
  margin: 20px;
}

.filters h2 {
  margin-bottom: 0.5rem;
}

.filters form {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.filter-group {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 20px;
}

.filter-group label {
  margin-bottom: 0.5rem;
  color: #ecf0f1;
}

.filter-group input,
.filter-group .dropdown__control {
  padding: 0.5rem;
  border-radius: var(--border-radius-1);
  border: 1px solid #34495e;
  background-color: #34495e;
  color: #ecf0f1;
}

.filter-group .dropdown__control {
  display: flex;
  align-items: center;
}

.filter-group .dropdown__menu {
  background-color: #34495e;
  color: #ecf0f1;
}

.filter-group .dropdown__option--is-focused {
  background-color: #2c3e50;
}

/* New styles for top products list */
.top-products, .most-frequent-products {
  width: 300px;
  display: inline-block;
  vertical-align: top;
  border-radius: 8px;
  background: var(--color-white);
  padding: 10px;
  text-align: left;
  margin-right: 2.5%;
  box-shadow: var(--box-shadow);
}

.top-products:last-child, .most-frequent-products:last-child {
  margin-right: 0; /* Remove margin for the last item */
}

.top-products-title, .most-frequent-products-title {
  text-align: left;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.top-products ul, .most-frequent-products ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.top-product-item, .most-frequent-product-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  transition: background 300ms ease;
}

.top-product-item:hover, .most-frequent-product-item:hover {
  background: #64686b5d;
}

.top-product-rank, .most-frequent-product-rank {
  font-weight: bold;
  color: #ecf0f1;
}

.top-product-name, .most-frequent-product-name {
  flex: 1;
  margin-left: 0.5rem;
  color: #ecf0f1;
}

.top-product-profit {
  margin-right: 0.5rem;
  color: #4cd586;
}

.most-frequent-product-count {
  margin-right: 0.5rem;
  color: #ffffff;
}

.top-product-link {
  color: #ffffff; /* blue for links */
  text-decoration: none!important;
}

.top-product-link:hover {
  text-decoration: underline;
}

/* Slider */

.horizontal-slider {
  width: 100%;
  margin: 15px 0;
  position: relative;
}

.example-thumb {
  height: 20px;
  width: 20px;
  background-color: #2c3e50;
  border-radius: 50%;
  cursor: grab;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Add subtle shadow */
  border: 2px solid white;
  top: 50%;
  transform: translateY(-50%);
}

.example-track {
  background: #232c35;
  height: 10px; /* Increased height for better visibility */
  border-radius: 5px; /* Rounded edges */
  top: 50%;
  transform: translateY(-50%);
  height: 10px; /* Increased height for better visibility */
}

.example-track-1 {
  background: #2c3e50; /* Different color for the active range */
  border-radius: 5px; /* Rounded edges for the active range */
}

.profit-range-values {
  display: flex;
  justify-content: space-between;
  color: #ecf0f1;
  margin-top: 5px; /* Space between slider and values */
}

.profit-range-values span {
  font-weight: bold; /* Make the values bold */
  width: 60px; /* Adjust width for better alignment */
  text-align: center; /* Center align the values */
  white-space: nowrap; /* Prevent text from wrapping */
}

.additional-info {
  width: 300px;
  display: inline-block;
  vertical-align: top;
  border-radius: 8px;
  background: var(--color-white);
  padding: 10px;
  text-align: left;
  margin-right: 2.5%;
  box-shadow: var(--box-shadow);
}

.additional-info-title {
  text-align: left;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.additional-info ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.additional-info-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  transition: background 300ms ease;
}


.status-container {
  width: 300px;
  display: inline-block;
  vertical-align: top;
  border-radius: 8px;
  background: var(--color-white);
  padding: 10px;
  text-align: left;
  margin-right: 2.5%;
  box-shadow: var(--box-shadow);
}

.status-title {
  text-align: left;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.prod-dropdown-content {
  background: #81868a23; 
}

.prod-dropdown {
  padding: 10px;
  z-index: 10;
  width: 150px;
}

.prod-dropdown input {
  width: 30%;
  background: rgba(128, 128, 128, 0);
  border-radius: 0.5rem;
  border: 2px solid white;
  padding: 5px;
  color: white;
  text-align: center;
  -webkit-appearance: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.prod-dropdown button {
  width: 100%;
}

.error {
  color: red;
}

.success {
  color: green;
}

.prod-title {
  font-size: 14px;
  text-align: center;
}

.edit-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.prod-dropdown {
  display: flex;
  align-items: center;
  width: 400px;
  gap: 20px;
}

.prod-dropd-btn:hover {
  background: var(--color-primary);;
  border-radius: 0.5rem;
  color: white;

}

.prod-dropd-btn {
  width: 30%!important;
}

/* Megamenu styles */
.megamenu {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  background-color: #34495e;
  padding: 20px;
  display: none; /* Hidden by default */
  z-index: 1000;
  border-radius: 8px;
}

.megamenu.open {
  display: flex; /* Show the menu when open */
}

.megamenu-column {
  flex: 1;
  margin-right: 20px;
}

.megamenu-column:last-child {
  margin-right: 0;
}

.megamenu h3 {
  color: #ecf0f1;
  margin-bottom: 10px;
}

.megamenu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.megamenu ul li {
  padding: 5px 0;
}

.megamenu ul li a {
  color: #ecf0f1;
  text-decoration: none;
}

.color-picker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.color-picker-header h3 {
  margin: 0;
  font-size: 14px;
  color: #fff;
}

.color-picker-content {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 10px;
}

.color-box {
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: 1px solid #fff;
  margin: 0 5px;
}

.red {
  background-color: rgba(255, 0, 0, 0.3); /* Adjust the opacity as needed */
}

.yellow {
  background-color: rgba(235, 235, 87, 0.527); /* Adjust the opacity as needed */
}

.green {
  background-color: rgba(0, 255, 0, 0.3); /* Adjust the opacity as needed */
}

.color-box.reset {
  background-color: rgba(128, 128, 128, 0.096);
}

.material-symbols-outlined {
  cursor: pointer;
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin: 20px 0;
  padding: 0 15px;
}

.product-card {
  background: rgba(45, 45, 45, 0.5); /* Semi-transparent dark background */
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  color: #ffffff; /* White text */
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  backdrop-filter: blur(10px); /* Adds a blur effect */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}

.product-image {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9; /* Ensures the image maintains aspect ratio */
  background-color: #e0e0e0; /* Placeholder background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-image img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.platform-tag {
  position: absolute;
  top: 8px;
  right: 8px;
  background: #ff5a5f; /* Example color for platform tag */
  color: white;
  font-size: 0.8em;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
}

.product-info {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.product-grid-title {
  text-align: left;
}

.product-title {
  font-size: 1.1em;
  font-weight: bold;
  margin: 0;
  color: #ffffff;
}

.product-storage {
  font-size: 0.9em;
  color: #d1d1d1;
}

.product-price {
  font-size: 1.2em;
  font-weight: bold;
  color: #29b8d8; /* Highlighted price */
}

.product-details {
  font-size: 0.85em;
  color: #9e9e9e;
}

.product-date {
  font-size: 0.75em;
  color: #9e9e9e;
}

.product-link {
  margin-top: 10px;
  text-align: center;
  padding: 10px;
  background: #29b8d8; /* Button color */
  color: #ffffff;
  text-decoration: none;
  font-size: 0.9em;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.product-link:hover {
  background: #1f8aa4;
}