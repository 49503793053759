.sheet-edit {
  padding: 20px;
  border-radius: 10px;
  color: white;
}

.sheet-edit h2 {
  margin-bottom: 20px;
}

.sheet-edit input[type="text"] {
  margin-bottom: 10px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #3c3c3c75;
  color: white;
}

.sheet-edit button {
  padding: 10px;
  margin-right: 10px;
  border: none;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: white;
  cursor: pointer;
}

.sheet-edit button:hover {
  background-color: #555;
}

.product-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  margin-top: 20px;
}

.product-table th, .product-table td {
  border: 1px solid #444;
  padding: 10px;
  text-align: left;
  color: white;
}

.product-table th {
  background-color: #5353534a;
}

.product-table tr{
  border-bottom: 1px solid #2f3239;
}

.product-table input[type="checkbox"] {
  margin: 0;
  width: 20px;
  height: 20px;
  display: inline-block;
  visibility: visible;
}

.product-table input[type="text"] {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #3330;
  color: white;
}

.product-table select {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #4440;
  color: white;
  cursor: pointer;
  transition: 0.3s;
}

.product-table select:hover {
  background: #4443;
}

.buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.save-prices, .delete-products, .add-product {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: white;
  cursor: pointer;
}

.save-prices:hover, .delete-products:hover, .add-product:hover {
  background-color: #555;
}

.delete-products {
  background-color: red;
}

.delete-products:hover {
  background-color: darkred;
}

.success-message {
  color: green;
  margin-bottom: 10px;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

/* Highlight duplicate products */
.duplicate {
  background: #8080803a!important;
}

/* Shake animation */
.shake {
  animation: shake 0.5s;
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}