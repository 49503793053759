.payment-result-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.payment-result-card {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 90%;
}

.success-icon {
  font-size: 48px;
  color: #4caf50;
  margin-bottom: 1rem;
}

.error-icon {
  font-size: 48px;
  color: #f44336;
  margin-bottom: 1rem;
}

.cancel-icon {
  font-size: 48px;
  color: #ff9800;
  margin-bottom: 1rem;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto 1rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.continue-button {
  margin-top: 1.5rem;
  padding: 0.8rem 1.5rem;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.continue-button:hover {
  background-color: #1976d2;
}

h2 {
  color: #333;
  margin-bottom: 1rem;
}

p {
  color: #666;
  line-height: 1.5;
  margin-bottom: 1rem;
}
