/* Tracking.css */
.dropdown {
    position: relative;
    display: inline-block;
    width: 200px;
  }
  
  .dropdown-btn {
    width: 100%;
    padding: 10px;
    cursor: pointer;
    background: #34495e;
    color: #ecf0f1;
    border: none;
    border-radius: 4px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #2c3e50;
    min-width: 400px; /* Adjust the width as needed */
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 4px;
    padding: 10px;
    color: #ecf0f1;
  }
  
  .dropdown-content.show {
    display: block;
    display: flex; /* Use flex to create columns */
  }
  
  .dropdown-content .category {
    flex: 1; /* Each category will take up equal space */
    margin-right: 10px;
  }
  
  .dropdown-content .category:last-child {
    margin-right: 0;
  }
  
  .dropdown-content .category div {
    margin-left: 20px;
  }
  
  .dropdown-catogary-title {
    color: rgb(255, 255, 255)!important;
    font-size: 14px;
    font-weight: 500;
  }
  
  .dropdown-content input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .dropdown-content label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .dropdown-content .category label {
    margin-top: 10px; /* Add spacing between the category label and checkboxes */
    margin-bottom: 10px; /* Add spacing below the category label */
  }
  
  .dropdown-content .category div label {
    margin-top: 0; /* Remove top margin from inner labels */
  }
  