
.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    background: url(http://localhost:3000/static/media/profitxbg2.1131859….png) no-repeat center center fixed;
    background-size: cover;
    color: var(--color-dark);
    border-bottom: 1px solid rgba(194, 208, 228, .2);
  }
  
  .page-info {
    align-items: center;
    gap: 2rem;
  }
  
  .page-info h1 {
    margin: 0;
    font-size: 1.8rem;
  }
  
  .page-info .date {
    background: var(--color-light);
    border-radius: var(--border-radius-1);
    padding: 0.5rem 1.6rem;
  }
  
  .user-info {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding-right: 50px;
  }
  
  .theme-toggler {
    background: var(--color-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.6rem;
    width: 4.2rem;
    cursor: pointer;
    border-radius: var(--border-radius-1);
  }
  
  .theme-toggler span {
    font-size: 1.2rem;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .theme-toggler span.active {
    background: var(--color-primary);
    color: white;
    border-radius: var(--border-radius-1);
  }
  
  .profile {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  
  .profile .info {
    text-align: right;
  }