
  html {
    font-size: 14px;
  }
  
  body {
    width: 100vw;
    height: 100vh;
    font-family: 'Poppins', sans-serif;
    font-size: 0.88rem;
    background: var(--color-background);
    user-select: none;
    overflow-x: hidden;
    color: var(--color-dark);
    background: url('../assetes/img/profitxbg2.png') no-repeat center center fixed;
    background-size: cover;
    color: white;
  }
  
  .dashboard-container {
    display: grid;
    width: 96%;
    margin: 0 auto;
    gap: 1.8rem;
    grid-template-columns: 1fr 300px;
  }

  .rect-rightBg {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 340px;
    height: auto;
    z-index: -1;
  }
  

.main-content {
  grid-column: 1;
  grid-row: 1;
}

/* main */

main {
    margin-top: 0.4rem;
}

main .date {
    display: inline-block;
    background: var(--color-light);
    border-radius: var(--border-radius-1);
    margin-top: 1rem;
    padding: 0.5rem 1.6rem;
}

main .date input[type='date']{
    background: transparent;
    color: var(--color-dark);
}

main .insights {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.6rem;
    margin-top: 1.8rem;
    padding: 20px;
}

main .insights > div {
    background: var(--color-white);
    padding: var(--card-padding);
    border-radius: var(--card-border-radius);
    margin-top: 1rem;
    box-shadow: var(--box-shadow);
    transition: all 700ms;
}

main .insights > div:hover {
    box-shadow: none;
    
}

main .insights > div span {
    padding: 0.5rem;
    border-radius: 50%;
    background: var(--color-primary);
    color: var(--color-white);
    font-size: 2rem;
}

main .insights > div.expenses span {
    background: rgb(235, 121, 121);
}

main .insights > div.income span {
    background: var(--color-success);
}

main .insights > div .middle {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

main .insights h3 {
    margin: 1rem 0 0.6rem;
    font-size: 1rem;
}

main .insights .progress {
    position: relative;
    width: 92px;
    height: 92px;
    border-radius: 50%;
}

main .insights svg {
    width: 7rem;
    height: 7rem;
}

main .insights svg circle {
fill: none;
stroke: var(--color-primary);
stroke-width: 14;
stroke-linecap: round;
transform: translate(5px, 5px);
stroke-dasharray: 110;
stroke-dashoffset: 92;
}

main .insights .sales svg circle {
    stroke-dashoffset: -30;
    stroke-dasharray: 200;
}

main .insights .expenses svg circle {
    stroke-dashoffset: 20;
    stroke-dasharray: 80;
}

main .insights .income svg circle {
    stroke-dashoffset: 35;
    stroke-dasharray: 110;
}

main .insights .progress .number {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

main .insights small {
    margin-top: 1.3rem;
    display: block;
}

/* ========= Recent Products ======== */

main .recent-products {
    margin-top: 2rem;
    padding: 20px;
}

main .recent-products h2 {
    margin-bottom: 0.8rem;
}

main .recent-products table {
    background: var(--color-white);
    width: 100%;
    border-radius: var(--card-border-radius);
    padding: var(--card-padding);
    text-align: center;
    box-shadow: var(--box-shadow);
    transition: all 700ms;
}


main table tbody td {
    height: 2.8rem;
    border-bottom: 1px solid rgb(255 255 255 / 22%);
    color: var(--color-dark-variant);
}

main table tbody tr:last-child td{
    border: none;
}

main .recent-products a {
    text-align: center;
    display: block;
    margin: 1rem auto;
    color: var(--color-primary);
}

/* RIGHT */

.right {
    grid-column: 2;
    grid-row: 1;
    margin-top: 1.4rem;
    width: 260px;
}

.right .top {
    display: flex;
    justify-content: end;
    gap: 2rem;
    }
    

.right .top button {
display: none;
}

.right .theme-toggler {
    background: var(--color-light);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.6rem;
    width: 4.2rem;
    cursor: pointer;
    border-radius: var(--border-radius-1);
}

.right .theme-toggler span {
font-size: 1.2rem;
width: 50%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
}

.right .theme-toggler span.active {
background: var(--color-primary);
color: white;
border-radius: var(--border-radius-1);
}

.right .top .profile {
    display: flex;
    gap: 2rem;
    text-align: right;
}

/* RENCENT UPDATES */


.right .recent-updates h2{
    margin-bottom: 0.8rem;

}

.right .recent-updates .updates{
    background: var(--color-white);
    padding: var(--card-padding);
    border-radius: var(--card-border-radius);
    box-shadow: var(--box-shadow);
    transition: all 700ms;
}

.right .recent-updates .updates:hover {
    box-shadow: none;
}

.right .recent-updates .updates .update {
    display: grid;
    grid-template-columns: 2.6rem auto;
    gap: 1rem;
    margin-bottom: 1rem;
}

/* SALES ANALYTICS */

.right .sales-analytics {
    margin-top: 2rem;
}

.right .sales-analytics h2 {
    margin-bottom: 0.8rem;
}

.right .sales-analytics .item {
    background: var(--color-white);
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.7rem;
    padding: 1.4rem var(--card-padding);
    border-radius: var(--border-radius-3);
    box-shadow: var(--box-shadow);
    transition: all 700ms;
}

.right .sales-analytics .item:hover {
    box-shadow: none;
}

.right .sales-analytics .item .right {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin: 0;
    width: 100%;
}

.right .sales-analytics .item .icon {
    padding: 0.6rem;
    color: var(--color-white);
    border-radius: 50%;
    background: var(--color-danger);
    display: flex;
}

.right .sales-analytics .item.offline .icon {
    background: var(--color-danger-red);
}

.right .sales-analytics .item.customers .icon {
    background: var(--color-success);
}

.right .sales-analytics .add-product {
    background-color: transparent;
    border: 2px dashed var(--color-primary);
    color: var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.right .sales-analytics .add-product:hover {
    background: var(--color-primary);
    color: white;
}

.right .sales-analytics .add-product div {
    display: flex;
    align-items: center;
    gap: 0.6rem;
}

.right .sales-analytics .add-product div h3 {
    font-weight: 600;
}

.recent-products table .positive-profit {
    color: green;
  }