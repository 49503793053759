/* analytics.css */

.analytics-container {
  width: 100%;
  margin-top: 3rem;
  color: #fff;
  text-align: center;
}

.overview-cards {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.card {
  flex: 1;
  background: var(--color-white);
  padding: 1rem;
  border-radius: var(--card-border-radius);
  box-shadow: var(--box-shadow);
  margin: 0 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card h3 {
  margin-bottom: 1rem;
}

.top-products {
  width: 300px;
  display: inline-block;
  vertical-align: top;
  border-radius: 8px;
  background: var(--color-white);
  padding: 10px;
  text-align: left;
  margin-right: 2.5%;
  box-shadow: var(--box-shadow);
}

.top-products h2 {
  text-align: left;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.top-products ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.top-product-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  transition: background 300ms ease;
}

.top-product-item:hover {
  background: #64686b5d;
}

.top-product-rank {
  font-weight: bold;
  color: #ecf0f1;
}

.top-product-name {
  flex: 1;
  margin-left: 0.5rem;
  color: #ecf0f1;
}

.top-product-profit {
  margin-right: 0.5rem;
  color: #4cd586;
}

.charts {
  margin-top: 2rem;
}

.charts h2 {
  margin-bottom: 1rem;
  color: #ecf0f1;
}
